import React from 'react';
import ClassNames from 'classnames';
import componentStyles from './TextInput.module.scss';
import { IoIosWarning } from 'react-icons/io';

const TextInput = ({
	name,
	label,
	type,
	size,
	id,
	value,
	invalid,
	required,
	errorMsg,
	changeHandler,
	placeholder,
	...props
}) => {
	return (
		<div
			className={
				size === 'sm'
					? ClassNames(componentStyles.inputContainer, componentStyles.sm)
					: componentStyles.inputContainer
			}
		>
			<label
				className={
					invalid ? ClassNames(componentStyles.textLabel, componentStyles.error) : componentStyles.textLabel
				}
				htmlFor={name}
			>
				{label}
				{required && <span className={ClassNames(componentStyles.required, componentStyles.error)}>*</span>}
			</label>

			<input
				className={
					invalid ? ClassNames(componentStyles.textInput, componentStyles.error) : componentStyles.textInput
				}
				id={id}
				type={type ? type : 'text'}
				cols={type === 'textarea' && '50'}
				rows={type === 'textarea' && '4'}
				name={name}
				valid={invalid ? 'false' : 'true'}
				onChange={changeHandler ? changeHandler : ''}
				placeholder={placeholder ? placeholder : ''}
				value={value ? value : ''}
			/>

			{errorMsg && (
				<div className={componentStyles.errorMsg}>
					<IoIosWarning /> <span>{errorMsg ? errorMsg : 'This is a required field.'}</span>
				</div>
			)}
		</div>
	);
};
export default TextInput;
