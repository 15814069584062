import React, { useState, useEffect } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useIntl } from 'gatsby-plugin-intl';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import Alert from '../../components/Alert/Alert';

const SubscriptionForm = () => {
	const intl = useIntl();
	const [submitDisable, setSubmitDisable] = useState(true);
	const [values, setValue] = useState({
		FNAME: '',
		LNAME: '',
		email: ''
	});
	const [errors, setErrors] = useState({
		FNAME: '',
		LNAME: '',
		email: ''
	});
	const [notification, setNotification] = useState({
		text: '',
		type: ''
	});

	// const handleTextChange = e => setEmail(e.target.value)
	const handleTextChange = (e) => {
		const value = e.target.value;
		const key = e.target.name;
		setValue({
			...values,
			[key]: value
		});
		if (value !== '' && value) {
			setErrors({
				...errors,
				[key]: ''
			});
		}
	};

	const handleValidation = () => {
		let formIsValid = true;
		let currentErrors = {};

		for (var i = 0; i < Object.keys(values).length; i++) {
			if (values[Object.keys(values)[i]] === '' || !values[Object.keys(values)[i]]) {
				formIsValid = false;
				currentErrors[Object.keys(values)[i]] = 'This is a required field.';
			} else {
				currentErrors[Object.keys(values)[i]] = '';
			}
		}

		setErrors(currentErrors);
		return formIsValid;
	};

	useEffect(() => {
		if (values.FNAME !== '' && values.LNAME !== '' && values.email !== '') setSubmitDisable(false);
		else setSubmitDisable(true);
	}, [values]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (handleValidation()) {
			addToMailchimp(values.email, {
				FNAME: values.FNAME,
				LNAME: values.LNAME
			})
				.then((data) => {
					setNotification({
						text: intl.formatMessage({ id: 'newsletter.thanks' }),
						type: 'success'
					});
					setSubmitDisable(true);
				})
				.catch(() => {
					setNotification({
						text: intl.formatMessage({ id: 'newsletter.error' }),
						type: 'error'
					});
				});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			{(errors.FNAME !== '' || errors.LNAME !== '' || errors.email !== '') && (
				<Alert type='error'>
					<span>{intl.formatMessage({ id: 'newsletter.incompleteMsg' })}</span>
				</Alert>
			)}
			{notification.text !== '' && (
				<Alert type={notification.type}>
					<span>{notification.text}</span>
				</Alert>
			)}

			<TextInput
				name='FNAME'
				label={intl.formatMessage({ id: 'newsletter.fName' })}
				id='mce-FNAME'
				type='text'
				placeholder='Jane'
				value={values.FNAME}
				required
				invalid={errors.FNAME !== ''}
				changeHandler={handleTextChange}
			/>

			<TextInput
				name='LNAME'
				label={intl.formatMessage({ id: 'newsletter.lName' })}
				id='mce-LNAME'
				type='text'
				placeholder='Doe'
				value={values.LNAME}
				required
				invalid={errors.LNAME !== ''}
				changeHandler={handleTextChange}
			/>

			<TextInput
				name='email'
				label={intl.formatMessage({ id: 'newsletter.email' })}
				id='mce-EMAIL'
				type='email'
				placeholder='ex. jane.doe@gmail.com'
				value={values.email}
				required
				invalid={errors.email !== ''}
				changeHandler={handleTextChange}
			/>

			<div style={{ marginTop: `3rem` }}>
				<Button
					id='newsletterSubmit'
					type='submit'
					text={intl.formatMessage({ id: 'newsletter.submit' })}
					disabled={submitDisable}
				/>
			</div>
		</form>
	);
};

export default SubscriptionForm;
